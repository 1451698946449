@import "https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&subset=latin-ext";
html, body, #surveyFormRoot {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
  font-family: Montserrat, sans-serif;
}

/*# sourceMappingURL=index.383a59b0.css.map */
